import React, { useEffect, useRef } from "react";
import FirstSection from "./FirstSection";
import Header from "../Header";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FifthSection from "./FifthSection";
import SixthSection from "./SixthSection";
import Footer from "../Footer";

const Home = () => {
  const home = useRef(null);
  const contactUs = useRef(null);
  const aboutUs = useRef(null);

  const handleRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const generatePropsForHeader = () => {
    return {
      home,
      contactUs,
      aboutUs,
      handleRef,
    };
  };

  return (
    <>
      <Header {...generatePropsForHeader()} />
      <div ref={home}>
        {" "}
        <FirstSection />
      </div>
      <div ref={aboutUs}>
        <SecondSection />
      </div>
      <ThirdSection />
      <FifthSection />
      <FourthSection />
      <SixthSection />
      <div ref={contactUs}>
        <Footer />
      </div>
    </>
  );
};

export default Home;
