import React from "react";

const ThirdSection = () => {
  return (
    <div className="w-full mt-[190px] md:mt-0">
      <img
        src="/assets/images/thirdSection.png"
        className="w-full object-cover"
        alt=""
      />
    </div>
  );
};

export default ThirdSection;
