import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Footer = () => {
  const renderLogo = () => {
    return (
      <div className="col-span-3 md:col-span-4 w-20 md:w-60">
        <img
          className=" object-cover w-full"
          src="/assets/images/feature_logo.svg"
          alt=""
        />
      </div>
    );
  };

  const renderQuickLinksSection = () => {
    return (
      <div className="col-span-3">
        <p className=" text-sm md:text-2xl font-semibold mb-3 md:mb-4">
          Quick links
        </p>
        <p className="text-xs md:text-base text-[#213D39] font-light">Home</p>
        <p className="text-xs md:text-base text-[#213D39] font-light">
          About us
        </p>
        <p className="text-xs md:text-base text-[#213D39] font-light">
          Social links
        </p>
        <div className="flex gap-2 mt-3">
          <div className="md:w-7 ">
            <a href="https://instagram.com/feature_sports_academy?igshid=ZGUzMzM3NWJiOQ==" target="_blank" rel="noopener noreferrer">
              <img
                className="object-cover w-full"
                src="/assets/images/instagramIcon.svg"
                alt=""
              />
            </a>
          </div>
          <div className="md:w-7">
            <a href="https://api.whatsapp.com/send?phone=917416545241" target="_blank" rel="noopener noreferrer">
              <img
                className="object-cover w-full"
                src="/assets/images/whatsappIcon.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderContactSection = () => {
    return (
      <div className="flex flex-col gap-2 md:gap-5 pr-4 col-span-6 md:col-span-5">
        <p className=" text-sm md:text-2xl font-semibold mb-1 md:mb-2">
          Contact
        </p>
        <div>
          <div className="flex gap-2">
            <div className="pt-1">
              {" "}
              <i class="fa-regular fa-clock"></i>
            </div>
            <div className="flex flex-col">
              <p className="text-xs md:text-base text-[#213D39] font-light">
                Monday - Saturday : 9am to 6pm Closed: Sunday
              </p>
              <p className="text-xs md:text-base text-[#213D39] font-light">
                Closed: Sunday
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="pt-1">
            <i class="fa-solid fa-phone"></i>
          </div>
          <div>
            <p className="text-xs md:text-base text-[#213D39] font-light">
              +91- 7416545241
            </p>
            <p className="text-xs md:text-base text-[#213D39] font-light">
              +91- 98480 20048
            </p>
            <p className="text-xs md:text-base text-[#213D39] font-light">
              +91- 9154124328
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="pt-1">
            <i class="fa-solid fa-envelope"></i>
          </div>
          <p className="text-xs md:text-base text-[#213D39] font-light">
            info@featuresports.co
          </p>
        </div>

        <div className="flex gap-2">
          <div className="pt-1">
            <i class="fa-solid fa-location-dot"></i>
          </div>
          <p className="text-xs md:text-base text-[#213D39] font-light">
            Vishwamitra IT Milan enclave,opposite telecom Nagar park gachhibowli
          </p>
        </div>
      </div>
    );
  };

  const renderCopyRightText = () => {
    return (
      <p className="text-center font-medium text-xs md:text-base text-[#95a2a0] mt-7 md:mt-16">
        Copyright © 2023 Featuresports
      </p>
    );
  };
  return (
    <>
      <div className="grid grid-cols-12 px-2 md:px-10 pt-9 border-t-2">
        {renderLogo()}
        {renderQuickLinksSection()}
        {renderContactSection()}
      </div>
      {renderCopyRightText()}
    </>
  );
};

export default Footer;
