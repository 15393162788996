import React from "react";

const FifthSection = () => {
  return (
   <>
      <div className="hidden md:block w-full my-10 pl-16 ">
        <p className="text-[64px] leading-[115px] mr-3 font-bebas text-[#193B55]">About our Coaches</p>
        <div className="flex w-full  justify-between">
          <div className="flex flex-col gap-5 w-[50%] mt-10">
            <p className="text-base leading-[24.17px] text-[#193B55CC] w-10/12">
              Our sports organization is proud to employ a team of highly
              qualified, experienced, and disciplined coaches. Our coaches have a
              proven track record of success, having achieved excellence as
              players themselves and gone on to train other successful players.
              Many of our coaches are currently working at some of Hyderabad's
              best international schools, bringing their expertise and knowledge
              to our programs.
            </p>
            <p className="text-base leading-[24.17px] text-[#193B55CC] w-10/12">
              At Feature, we believe in providing our students with the highest
              quality instruction and guidance possible. That's why we are
              committed to recruiting the best coaches in the industry, who are
              passionate about their work and dedicated to helping students reach
              their full potential. Whether you are a beginner or an advanced
              athlete, our team of expert coaches are here to help you achieve
              your goals and succeed on and off the field.
            </p>
          </div>
          <div className="flex relative mr-32">
            <img
              className="w-full"
              src="/assets/images/fifthsection.png"
              alt=""
            />
            <div className="">
              <img
                className="absolute bottom-[-32px] right-[-54px] object-fit"
                src="/assets/images/arrowOrange.svg"
                alt=""
                height={40}
                width={100}
              />
            </div>
          </div>
        </div>
      </div>
  
      <div className="block md:hidden w-full my-5 px-7">
        <p className="text-[30px] uppercase font-bebas">About our Coaches</p>
        <div className="w-full justify-between">
          <p className="text-xs font-medium text-[#193B55] leading-[18.14px]">
            Our sports organization is proud to employ a team of highly qualified,
            experienced, and disciplined coaches. Our coaches have a proven track
            record of success, having achieved excellence as players themselves
            and gone on to train other successful players. Many of our coaches are
            currently working at some of Hyderabad's best international schools,
            bringing their expertise and knowledge to our programs.
          </p>
          <div className="flex mt-10 items-center">
            <p className="w-[40%] text-xs font-medium text-[#193B55] leading-[18.14px]">
              At Feature, we believe in providing our students with the highest
              quality instruction and guidance possible. That's why we are
              committed to recruiting the best coaches in the industry, who are
              passionate about their work and dedicated to helping students reach
              their full potential. Whether you are a beginner or an advanced
              athlete, our team of expert coaches are here to help you achieve
              your goals and succeed on and off the field.
            </p>
            <div className=" w-[60%] h-fit relative ml-1" >
              <img
                className=""
                src="/assets/images/fifthsection.png"
                alt=""
                width={200}
              />
              <div className="">
                <img
                  className="absolute bottom-[-20px] right-2 object-fit"
                  src="/assets/images/arrowOrange.svg"
                  alt=""
                  height={40}
                  // width={170}
                />
              </div>
            </div>
          </div>
  
          {/* <div className="flex relative mr-32">
            <img
              className="w-full"
              src="/assets/images/fifthsection.png"
              alt=""
            />
            <div className="">
              <img
                className="absolute bottom-[-32px] right-[-54px] object-fit"
                src="/assets/images/arrowOrange.svg"
                alt=""
                height={40}
                width={100}
              />
            </div>
          </div> */}
        </div>
      </div>
   </>
  );
};

export default FifthSection;
