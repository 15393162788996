import React, { useState } from "react";

const Header = (props) => {
  const [menuState, setMenuState] = useState(false);
  const { home, contactUs, aboutUs, handleRef } = props;

  const menuItems = [
    {
      label: "Home",
      ref: home,
    },
    {
      label: "About us",
      ref: aboutUs,
    },
    {
      label: "Contact us",
      ref: contactUs,
    },
  ];
  const renderLogo = () => {
    return (
      <div className="w-16 sm:w-36">
        <img
          src="/assets/images/feature_logo.svg"
          className="object-cover w-full"
          alt=""
        />
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div className="grid grid-cols-1 justify-self-end pt-3">
        <ul className="hidden sm:flex gap-10 ">
          {menuItems.map((item) => (
            <li>
              <a
                className="text-lg md font-medium"
                href={item.href}
                onClick={() => handleRef(item.ref)}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="sm:hidden" onClick={handleClick}>
          <img
            src="/assets/images/hamMenu.svg"
            className="object-cover w-full"
            alt=""
          />
        </div>
      </div>
    );
  };

  const handleClick = () => {
    setMenuState(!menuState);
  };

  const renderMenuMobile = () => {
    return (
      <div className="h-screen  bg-white w-full z-50" onClick={handleClick}>
        <ul className="flex flex-col items-center absolute top-[25%] left-[40%] gap-10">
          {menuItems.map((item) => (
            <li>
              <a
                className="text-lg md font-medium"
                href={item.href}
                onClick={() => handleRef(item.ref)}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <>
      <div className="w-full absolute top-0 grid grid-cols-2 px-10 pt-4 z-30">
        {renderLogo()}
        {renderMenu()}
      </div>
      {menuState ? renderMenuMobile() : null}
    </>
  );
};

export default Header;
