import React from "react";

const FirstSection = () => {
  return (
  
    <>
      <div className="hidden md:grid grid-cols-12 h-full bg-cover bg-cneter"
       style={{ backgroundImage: "url('/assets/images/Holder.jpg')" }}
      >
        <div className="grid col-span-7 content-center relative h-full">
          <div className=" ml-44 mt-20">
            <p className="text-[70px] text-[#193B55] leading-[20px] font-bebas">
              For the
            </p>
            <p
              className="text-[190px] text-[#193B55] leading-[192px] bg-cover font-bebas w-fit"
              style={{ backgroundImage: "url('/assets/images/splash.svg')" }}
            >
              Future
            </p>
            <p className="w-72 mb-20  text-base font-medium text-[#526C7FCC] leading-[24.19px] mt-5">
              At Feature, we believe that investing in the athletic potential of
              today's youth is the key to building a brighter future for all.
            </p>
            <a className="font-bold text-lg" href="#">
              Get Started now <span>{`>>`}</span>
            </a>
          </div>
        </div>
        <div className="relative col-span-5 h-full">
          <div className="absolute left-[-45%] bottom-[-25px] w-[80%]">
            <img
              className="w-full h-full"
              src="/assets/images/basketBallPlayer.png"
              alt=""
            />
          </div>
          <img
            className="w-full h-full"
            src="/assets/images/courtImage.png"
            alt=""
          />
        </div>
      </div>
      <div className="md:hidden relative">
        <img
          className="w-full h-[360px]"
          src="/assets/images/courtImage.png"
          alt=""
          // width={428}
          height={360}

        />
        <div className="absolute bottom-0">
          <div className=" p-5">
            <p className="text-[22px] text-[#193B55] leading-[20px] font-bebas">
              For the
            </p>
            <p
              className="text-[80px] text-[#193B55] leading-[80px] bg-cover font-bebas w-fit"
              style={{ backgroundImage: "url('/assets/images/splash.svg')" }}
            >
              Future
            </p>
            <p className="w-44  mb-5 text-xs font-medium text-[#526C7FCC] leading-[18.19px] mt-5">
              At Feature, we believe that investing in the athletic potential of
              today's youth is the key to building a brighter future for all.
            </p>
            <a className="font-bold text-xs" href="#">
              Get Started now <span>{`>>`}</span>
            </a>
          </div>
        </div>
        <div className=" absolute w-[200px] bottom-0 right-0">
          <img
            className="w-full"
            src="/assets/images/basketBallPlayer.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default FirstSection;
