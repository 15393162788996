import React from "react";

const SixthSection = () => {
  return (
    // <div className="flex w-full">
    //   <div className="w-2/6">
    //     <img
    //       className="w-full h-full object-cover"
    //       src="/assets/images/image3.png"
    //       alt=""
    //     />
    //   </div>
    //   <div className="w-2/6">
    //     <img
    //       className="w-full h-full object-cover"
    //       src="/assets/images/image2.png"
    //       alt=""
    //     />
    //   </div>
    //   <div className="w-2/6">
    //     <img
    //       className="w-full h-full object-cover"
    //       src="/assets/images/image1.png"
    //       alt=""
    //     />
    //   </div>
    // </div>

    <div className="grid grid-cols-3">
      <div className="relative">
        <a href="https://instagram.com/feature_sports_academy?igshid=ZGUzMzM3NWJiOQ==" target="_blank" rel="noopener noreferrer">
          <img
            className="w-full object-cover"
            src="/assets/images/image3.png"
            alt=""
          />
          <div class="absolute top-0 left-0 w-full h-full bg-black opacity-70 flex items-center justify-center">
            <img src="/assets/images/instagramAntd.png" alt="" className="block max-w-[40px] md:max-w-full h-auto" />
          </div>
        </a>
      </div>
      <div className="">
        <img
          className="w-full object-cover"
          src="/assets/images/image2.png"
          alt=""
        />
      </div>
      <div className="">
        <img
          className="w-full object-cover"
          src="/assets/images/image1.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default SixthSection;
