import React from "react";

const SecondSection = () => {
  return (
    <>
      <div className="hidden md:grid grid-cols-2 h-auto md:py-10">
        <div className="grid col-span-1 justify-items-center pr-10">
          <div className="absolute left-0">
            <img
              className="w-full h-auto"
              src="/assets/images/reactangle.png"
              alt=""
            />
          </div>
          <div className="flex flex-col justify-start w-fit pt-10 z-20">
            <div className=" flex items-center">
              <p className="mr-2 text-[60px] leading-[66px] font-bebas text-[#193B55]">
                ABOUT
              </p>{" "}
              <p className="text-[104px] leading-[115px] mr-3 font-bebas text-[#193B55]">
                FEATURE
              </p>
              <img src="/assets/images/arrowOrange.svg" alt="" />
            </div>
            <p className="w-[452px] text-xl leading-[24.17px] text-[#193B55CC]">
              For the past seven years, our sports organization has been
              dedicated to creating a better future for our country by providing
              high-quality services to clubs, schools, and communities
              nationwide. Our focus is on developing world-class athletes and
              strong citizens through a combination of commitment, discipline,
              positive thinking, and ambitious sports goals.
            </p>
            <p className="w-[452px] text-xl leading-[24.17px] text-[#193B55CC] mt-7">
              Our team of experienced coaches and trainers are passionate about
              their work and take great pride in helping young athletes reach
              their full potential.{" "}
            </p>
            <a className="text-lg font-bold text-[#193B55] pt-8" href="#">
              Learn More {`>>`}
            </a>
          </div>
        </div>
        <div className="col-span-1">
          <img className="w-full" src="/assets/images/section2.png" alt="" />
        </div>
      </div>
      
      <div className="flex md:hidden flex-col justify-center items-center relative w-full h-auto my-4">
        <div className="flex items-center justify-center w-full text-center">
          <p className="mr-2 text-[30px] leading-[36px] font-bebas">ABOUT</p>{" "}
          <p className="text-[80px] leading-[86px] mr-3 font-bebas">FEATURE</p>
          <img src="/assets/images/arrowOrange.svg" alt="" />
        </div>
        <div className="px-12 z-10">
          <p className="text-xs leading-[18.17px] text-[#193B55CC]">
            For the past seven years, our sports organization has been dedicated
            to creating a better future for our country by providing
            high-quality services to clubs, schools, and communities nationwide.
            Our focus is on developing world-class athletes and strong citizens
            through a combination of commitment, discipline, positive thinking,
            and ambitious sports goals.
          </p>
          <p className="text-xs leading-[18.17px] text-[#193B55CC] my-4">
            Our team of experienced coaches and trainers are passionate about
            their work and take great pride in helping young athletes reach
            their full potential.{" "}
          </p>
          <a className="text-xs text-right font-bold text-[#193B55] " href="#">
            Learn More {`>>`}
          </a>
        </div>
        <div className=" flex items-center absolute bottom-[-50%] right-2">
          <div className="pt-10 mr-5">
            <img
              className="object-fit"
              src="/assets/images/oppositeArrow.png"
              alt=""
              height={30}
              width={72}
            />
          </div>
          <div>
            <img
              className="w-full"
              src="/assets/images/section2Mobile.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSection;
