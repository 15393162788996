import React from "react";

const FourthSection = () => {
  return (
    // <div className="w-full flex justify-end">
    //   <div className="relative">
    //     <div className=" flex mr-5 rounded-l-md">
    //       <img
    //         className="object-cover w-full pt-10"
    //         src="/assets/images/team.png"
    //         alt=""
    //       />
    //     </div>
    //   </div>
    //   <div className="flex flex-col w-[50%] pt-11 bg-[#FFBE36] px-6 py-3">
    //     <p className="text-4xl font-bold font-bebas">OUR</p>
    //     <p className="text-[64px] font-bold font-bebas">WINNING FORMULA</p>
    //     <p className="w-96 text-sm font-medium leading-[25px]">
    //       Our sports training is planned and systematic, with a focus on
    //       analyzing each student's skills and categorizing them into beginners,
    //       intermediate, and advanced levels. We use a variety of teaching aids
    //       to ensure that every student receives appropriate instruction, and
    //       provide individualized attention and encouragement for those who need
    //       extra support. Whether you are a beginner or an advanced athlete, we
    //       are committed to fostering growth and development in all our students.
    //     </p>
    //     <a className="mt-10 font-bold text-lg mr" href="#">
    //       JOIN US NOW<span className="pl-2">{`>>`}</span>
    //     </a>
    //   </div>
    // </div>
    <>
      <div className="hidden md:grid grid-cols-2 relative py-10 px my-10 mr-16 ">
        <div className="col-span-1">
          <div className="absolute md:left-20 lg:left-24 xl:left-48 2xl:left-64 x top-0">
            <img
              className="pt-10"
              src="/assets/images/fourthSection.png"
              alt=""
              width={500}
            />
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col pt-11 pl-40 bg-[#FFBE36] py-3">
            <p className="text-4xl font-bold font-bebas text-[#193B55]">OUR</p>
            <p className="text-[64px] font-bold font-bebas text-[#193B55]">WINNING FORMULA</p>
            <p className="pr-4 text-sm leading-[24.17px] w-[360px]">
              Our sports training is planned and systematic, with a focus on
              analyzing each student's skills and categorizing them into
              beginners, intermediate, and advanced levels. We use a variety of
              teaching aids to ensure that every student receives appropriate
              instruction, and provide individualized attention and
              encouragement for those who need extra support. Whether you are a
              beginner or an advanced athlete, we are committed to fostering
              growth and development in all our students.
            </p>
            {/* <a className="mt-10 font-bold text-lg mr" href="#">
              JOIN US NOW<span className="pl-2">{`>>`}</span>
            </a> */}
          </div>
        </div>
      </div>
      {/* Mobile Design */}

      <div className="flex md:hidden flex-col pt-11 bg-[#FFBE36] my-5  relative image-frame">
        <div className="w-fit px-6">
          <p className="text-[22px] font-bold font-bebas">OUR</p>
          <p className="text-[30px] font-bold font-bebas">WINNING FORMULA</p>
          <p className=" text-xs font-medium ">
            Our sports training is planned and systematic, with a focus on
            analyzing each student's skills and categorizing them into
            beginners, intermediate, and advanced levels.
          </p>
        </div>
        <div className="flex mt-4 px-2">
          <div className="mr-2 image-section">
            <img
              className=""
              src="/assets/images/fourthSection.png"
              alt=""
              width={220}
            />
          </div>

          <p className="text-xs font-medium text-content">
            We use a variety of teaching aids to ensure that every student
            receives appropriate instruction, and provide individualized
            attention and encouragement for those who need extra support.
            Whether you are a beginner or an advanced athlete, we are committed
            to fostering growth and development in all our students.
          </p>
        </div>
      </div>
    </>
  );
};

export default FourthSection;
